import styles from './FeatureCard.module.scss';

import ArrowRound from '#components/ui/Icons/ArrowRound';
import LeakIcon from '#images/common/icon-leak.svg';
import LocationByLinkIcon from '#images/common/icon-location-by-link.svg';
import LocationByNumberIcon from '#images/common/icon-location-by-number.svg';
import MessageIcon from '#images/common/icon-message.svg';
import NicknameLookupIcon from '#images/common/icon-nickname-lookup.svg';
import SearchIcon from '#images/common/icon-search.svg';
import VoiceMessageIcon from '#images/common/icon-voice-message.svg';
import { prepareUrl } from '#utils/prepareUrl';
import { getFeatureNameStorageParam } from '#utils/getFeatureNameStorageParam';
import { clearFeaturesStorage } from '#utils/clearFeaturesStorage';

type Props = {
  feature: {
    title: string;
    description: string;
    type: string;
  };
  lang: string;
};

const FeatureCard = ({ feature, lang }: Props) => {
  const preparedLink = prepareUrl('/email', lang);

  const getActionDetails = (type: string) => {
    switch (type) {
      case 'locationByLink':
        return { icon: LocationByLinkIcon, href: '/ip-tracker' };
      case 'locationByNumber':
        return { icon: LocationByNumberIcon, href: '/' };
      case 'reversePhoneLookup':
        return { icon: SearchIcon, href: '/reverse-number-lookup' };
      case 'reverseNicknameLookup':
        return { icon: NicknameLookupIcon, href: '/reverse-username-lookup/' };
      case 'leakChecker':
        return { icon: LeakIcon, href: '/email' };
      case 'sendVoiceMsg':
        return { icon: VoiceMessageIcon, href: '/email' };
      case 'messageToLostPhone':
        return { icon: MessageIcon, href: '/email' };
      default:
        return { icon: null, href: null };
    }
  };

  const handleClick = () => {
    const currentFeature = getFeatureNameStorageParam(window.location.pathname);
    const featureHref = getActionDetails(feature.type).href;
    if (currentFeature) {
      clearFeaturesStorage();
      sessionStorage.setItem(currentFeature, 'true');
    }
    window.location.href = featureHref ? prepareUrl(featureHref, lang) : preparedLink;
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardWrapper} onClick={handleClick}>
        <div className={styles.cardTop}>
          <img
            src={getActionDetails(feature.type).icon?.src}
            alt={`${feature.type} icon`}
            loading="lazy"
            decoding="async"
            className={styles.cardTitleIcon}
          />
          <h3 className={styles.cardTitle}>{feature.title}</h3>
        </div>
        <div className={styles.cardContent}>{feature.description}</div>
        <div className={styles.cardFooter}>
          <img
            src={getActionDetails(feature.type).icon?.src}
            alt={`${feature.type} icon`}
            loading="lazy"
            decoding="async"
            className={styles.cardBackgroundIcon}
          />
          <button type="button" onClick={handleClick}>
            <ArrowRound />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
